import React from 'react';

export const LinkedinIcon = () => {
    return (<>
        <svg 
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 100 100"
        stroke="#511b1a"
        strokeWidth={7}
        >
            <path 
                className="cls-1" 
                strokeLinecap="round"
                strokeLinejoin="round" 
                d="M6.35,34.7H25.23v60.85H6.35s.31-60.53,0-60.85Z"
            />
            <path 
                className="cls-1" 
                strokeLinecap="round"
                strokeLinejoin="round" 
                d="M37.24,34.7h18.1v7.96s8.11-13.73,26.21-8.74c13.07,4.63,13.85,15.46,14.23,21.87v39.76h-18.63v-25.82c-.18-5.02,1.97-19.96-10.86-19.76-11.66,.18-10.78,13.35-10.78,13.35v32.23h-18.25s.47-60.85,0-60.85Z"
            />
            <circle 
                className="cls-1" 
                cx="15.37" 
                cy="15.6" 
                r="11.15"
            />
        </svg>
    </>)
}