import React from 'react';

export const GithubIcon = () => {
    return (<>
        <svg 
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 100 100"
        stroke="#511b1a"
        strokeWidth={7}
        >
            <path 
                className="cls-1"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M37.74,95.97l-.11-12.27s-7.49,2.42-13.54-.33c-6.05-2.75-7.38-7.04-7.38-7.04,0,0-1.65-5.72-6.71-8.81-5.06-3.08,.11-3.74,.11-3.74,0,0,5.72,.33,9.14,5.17s5.06,6.49,7.04,7.15,4.29,1.1,6.27,.77c1.98-.33,5.28-1.54,5.28-1.54,0,0,1.32-6.82,3.85-8.04,0,0-14.09-.88-21.46-9.47-7.38-8.59-6.38-22.57-6.38-22.57,0,0,.44-9.25,6.38-14.86,0-.11-3.3-6.66,.55-16.29,0-.06,6.93-1.05,16.84,6.33,0-.11,7.04-2.09,15.52-1.98,8.48,.11,15.3,1.98,15.3,1.98,0,0,8.48-6.71,16.51-6.27,.11-.11,4.07,8.81,.88,16.18,0,.11,6.38,5.72,6.38,18.16,0,6.75-2.97,19.26-11.89,23.89-7.82,4.51-16.4,4.73-16.4,4.73,0,0,4.29,2.97,4.4,10.9,.11,7.93-.11,17.94-.11,17.94"
            />
        </svg>
    </>)
}